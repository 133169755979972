import React from "react";
import { getSlideNumber } from "../../layouts/CarouselMobile";
import { ThreeStories } from "./ThreeStories";

export const Mercedes = () => {
  return (
    <div id="mercedes" className="item">
      <div
        className="fill"
        style={{
          backgroundImage: "url('/images/full_page/mercedes.jpg')",
        }}
      >
        <div className="container white-text" style={{ paddingTop: "6%" }}>
          <h1 className="museo-sans-500" style={{ marginTop: "0px" }}>
            mercedes
          </h1>
          <p className="museo-sans-100">
            It’s not often that a car company with such expertise fails to
            thrill its drivers, but the introduction of the S Class Sedan was
            one such rarity. This story, how one wins back a disappointed
            consumer of $100K product, is one with a happy ending.
          </p>
          <br />
          <p className="museo-sans-100">Take the Test Drive to the Driveway</p>
          <br />
          <p className="museo-sans-100">
            <strong className="museo-sans-500">The strategy: </strong> make
            owners of the S Class aware that Mercedes values not just owner
            buying power but their time and their opinions, and most importantly
            had heard the issues with last season’s model.
          </p>
          <br />
          <p className="museo-sans-100">
            <strong className="museo-sans-500">Execution:</strong> Through a
            dealer-activated program, a bespoke hand-delivered box was created
            and delivered. Inside the box were: material about the new S Class
            which indirectly answered every objection previously voiced, an
            offer to bring the new car to their home for a test period of a
            week. A survey about their present S Class compared to the new S
            Class.
          </p>
          <br />
          <p className="museo-sans-100">
            The sedan that drove like a sports car was saved by its own
            improvement and a clear strategy to communicate that fact.
          </p>
          <br />

          <a
            data-target="#myCarousel"
            data-slide-to={getSlideNumber(ThreeStories)}
          >
            <h4
              className="museo-sans-300 threeStories"
              style={{ color: "#fff" }}
            >
              {"<< Back"}
            </h4>
          </a>
        </div>
      </div>
    </div>
  );
};
Mercedes.slideName = "Mercedes";
