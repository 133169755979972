import React from "react";

export const WhatzBlue = ({ active }) => {
  return (
    <div id="whatz-blue" className={`${active ? "active " : ""}item`}>
      <div
        className="fill"
        style={{
          backgroundImage: "url(/images/full_page/whatzblue.jpg)",
        }}
      >
        <div className="container white-text" style={{ paddingTop: "5%" }}>
          <h1
            className="av-next-condensed-ulight text-center"
            style={{ fontSize: "10em", lineHeight: ".7" }}
          >
            WHATZ BLUE
          </h1>
          <h4
            className="helvetica-bold text-center"
            style={{ letterSpacing: "1.6px", fontSize: "1.9em" }}
          >
            News of human sustainability and wellness.
          </h4>
          <br />
          <br />
          <div style={{ height: "50%", width: "100%" }}>
            <img src="images/delos-article-pic.jpg" height="" width="100%" />
          </div>
          <h4 className="helvetica-light">
            DELOS goes wide on Wellness in the Built Environment
          </h4>
          <h5>Oct 1, 2015</h5>
          <p className="helvetica-light">
            When Paul Scialla said, "Why settle for buildings that are just good
            for the planet, why not make them good for the people who live,
            work, and play in the them?" those who work with him knew he would
            never be satisfied with certifying one building at a time. This week
            Paul took the next logical step in developing a world more
            completely committed to wellness. From the stage at CGI, Bill
            Clinton himself go to live.clintonglobalinitiative.org view Closing
            Plenary session) announced that DELOS and Strategic Property
            Partners had formed a joint venture to create the world's first WELL
            Certified™ city district in Tampa, Florida.{" "}
            <a
              style={{ backgroundColor: "white" }}
              href="#"
              // TODO change implement this onClick handler function
              // onClick="toggleOverflow('moreArticle');"
            >
              Read more {">"}
            </a>
          </p>
          <p
            id="moreArticle"
            className="helvetica-light"
            style={{ display: "none" }}
          >
            The Tampa city district will serve as an example to the world that
            city design can be healthy. Delos will deploy its proven research
            and development capabilities to identify city-scale development
            strategies to support public health and wellness. The principles and
            foundation of the WELL Building Standard - which focuses on seven
            categories of building performance, including air, water,
            nourishment, light, fitness, comfort and mind - will be applied to
            the project. All buildings within the Tampa city district will
            pursue WELL Certification, including new buildings such as a 400-500
            room hotel, a 650,000 square foot signature office tower, the
            University of South Florida Morsani College of Medicine and Heart
            Institute, and approximately 200,000 square feet of retail,
            restaurant and entertainment space. Additionally, the community
            itself will become the world's first WELL Certified city district
            and will feature design and technology strategies including enhanced
            walkability, abundant green space including low pollen trees, sound
            barriers to support acoustic comfort, access to healthy foods, green
            infrastructure, daily monitoring and reporting of district air
            quality, and access to the amenities of an urban waterfront - all of
            which contribute to the wellness and quality of life of the people
            who live and work in the development. See the full report{" "}
            <a href="http://bit.ly/1M40mdU">http://bit.ly/1M40mdU</a>.
          </p>
          <br />
          <br />
          <br />

          <div style={{ height: "50%", width: "100%" }}>
            <img src="images/steph-illustration.jpg" height="" width="100%" />
          </div>
          <h4 className="helvetica-light">
            What's a Chief Health Officer and why you want one.
          </h4>
          <h5>Feb 29, 2016</h5>
          <p className="helvetica-light">
            A chief health officer is the majordomo of a company's wellbeing.
            The CHO sets strategy and policy, hires consultants to create
            body/mind/spirit fitness programs, and stays out in front of the
            curve when new opportunities occur.
          </p>

          <br />
          <p>
            And the more we learn about wellness-in-the-workplace, the more
            important having that subject expert seems
          </p>
          <br />

          <p>
            Gallup research shows that employees thriving in all elements of
            well-being are{" "}
            <a
              href="#"
              style={{ backgroundColor: "white" }}
              // TODO change implement this onClick handler function
              // onClick="toggleOverflow('moreArticle2');"
            >
              Read more {">"}
            </a>
          </p>
          <div id="moreArticle2" style={{ display: "none" }}>
            <ul>
              <li>43% more likely to volunteer</li>
              <li>
                36% more likely to report a full recovery after an illness,
                injury, or hardship
              </li>
              <li>
                More than twice as likely to say they always adapt well to
                change
              </li>
              <li>Miss 41% less work as a result of poor health</li>
              <li>
                Are 81% less likely to seek out a new employer in the next year
              </li>
            </ul>
            <p>
              <strong>
                In short they are&nbsp;engaged, adaptable, resilient… and
                retained. &nbsp;
              </strong>
            </p>
            <p>
              But achieving the above goals is not as easy as it might
              appear.&nbsp; Organizations have tried to achieve higher employee
              well-being through internal corporate wellness programs; however,
              many of these programs miss the mark on actually improving
              well-being and creating positive impacts.
            </p>
            <p>
              Gallup research also shows that 60% of U.S. employees who work for
              companies with a wellness program know that the program exists,
              and only 40% of those who are aware of the program say they
              actually participate in it -- that's just 24% of employees
              participating. More importantly, of the employees participating in
              a company-sponsored wellness program, only 12% strongly agree that
              they have substantially increased overall well-being because of
              their employer. In fact, most employees see their job as a
              negative contributor to their overall well-being.
            </p>
            <p>
              Clearly, unless well-being programs are headed by a highly
              competent expert who considers employees' mind/body/spirit
              fitness, companies are not achieving peak performance
              metrics.&nbsp; So the CHO is pivotal.&nbsp; With a &nbsp;30,000
              ft. view and able to tap the right resources and set comprehensive
              strategies, the CHO may be the lynchpin that has been missing,
              preventing corporate wellness plans from achieving effective
              results.
            </p>
            <p>
              "About fifteen years ago, companies with the greatest advances in
              technology all appointed&nbsp;Chief Technology Officers, and
              allowed there to be kind of a uniform response to this
              technology," David Agus (a strong proponent of CHOs told{" "}
              <em>CBS This Morning</em>. "Well, that's happening in health now.
              We're literally at this transformation in health and so we need to
              change. Chief health officers is the new way where you can align
              for the employees, the products, and the mission of the company."
            </p>
            <p>
              "Much in the way a chief technology officer focuses on the
              scientific and technological issues within a company, a chief
              health officer would be charged with staying abreast of the rapid
              changes in medicine that make it easier to maintain a
              healthy&nbsp;workforce," &nbsp;Agus wrote, in the{" "}
              <em>Wall Street Journal.</em>
            </p>
            <p>
              He&nbsp;cites statistics from a recent study from the Center of
              Disease Control and Prevention showing that 86 percent of U.S.
              employees are above normal weight or have a chronic health
              condition, and that they miss an estimated 450 million extra days
              of work a year compared with healthy workers. This costs American
              businesses from $150 billion to a little more than $225 billion
              in&nbsp;lost productivity.
            </p>
            <p>
              “A chief health officer would be an uncomplicated solution,” Agus
              said, “to not only help keep workers healthy, but improve the
              company's bottom line.”
            </p>

            <p
              id="moreArticle2"
              className="helvetica-light"
              style={{ display: "none" }}
            >
              The Tampa city district will serve as an example to the world that
              city design can be healthy. Delos will deploy its proven research
              and development capabilities to identify city-scale development
              strategies to support public health and wellness. The principles
              and foundation of the WELL Building Standard - which focuses on
              seven categories of building performance, including air, water,
              nourishment, light, fitness, comfort and mind - will be applied to
              the project. All buildings within the Tampa city district will
              pursue WELL Certification, including new buildings such as a
              400-500 room hotel, a 650,000 square foot signature office tower,
              the University of South Florida Morsani College of Medicine and
              Heart Institute, and approximately 200,000 square feet of retail,
              restaurant and entertainment space. Additionally, the community
              itself will become the world’s first WELL Certified city district
              and will feature design and technology strategies including
              enhanced walkability, abundant green space including low pollen
              trees, sound barriers to support acoustic comfort, access to
              healthy foods, green infrastructure, daily monitoring and
              reporting of district air quality, and access to the amenities of
              an urban waterfront – all of which contribute to the wellness and
              quality of life of the people who live and work in the
              development. See the full report{" "}
              <a href="http://bit.ly/1M40mdU">http://bit.ly/1M40mdU .</a>
            </p>
            <br />
          </div>
          <br />
          <a href="pick-5">
            <h4
              className="museo-sans-300 threeStories"
              style={{ color: "#fff" }}
            >
              {"<< Back"}
            </h4>
          </a>
          <br />
        </div>
      </div>
    </div>
  );
};
WhatzBlue.slideName = "WhatzBlue";
WhatzBlue.pathname = "/whatz-blue";
