import React from "react";
import { getSlideNumber } from "../../layouts/CarouselMobile";
import { ThreeStories } from "./ThreeStories";

export const Stories = ({ active, updateActiveSlide }) => {
  return (
    <div id="stories" className={`${active ? "active " : ""}item`}>
      <div
        className="fill"
        style={{
          backgroundImage: "url('/images/full_page/stories.jpg')",
        }}
      >
        <div className="container white-text">
          <div className="text-right" style={{ padding: "2% 8%" }}>
            <h1
              className="museo-sans-100"
              style={{ paddingTop: "36%", fontSize: "6.75em" }}
            >
              stories
            </h1>
            <span className="storiesDesktop">
              <h4 className="museo-sans-300">
                How you tell them makes all the difference.
              </h4>
            </span>
            <span className="storiesMobile">
              <h4 className="museo-sans-300">How you tell them</h4>
              <h4 className="museo-sans-300">makes all the difference.</h4>
            </span>
            <a
              data-target="#myCarousel"
              data-slide-to={getSlideNumber(ThreeStories)}
              onClick={(event) => {
                event.preventDefault();
                updateActiveSlide(getSlideNumber(ThreeStories));
                console.log("go to slide", getSlideNumber(ThreeStories));
              }}
            >
              <h4
                className="museo-sans-300 threeStories"
                style={{ color: "#fff" }}
              >
                SEE HOW WE TOLD THREE.
              </h4>
            </a>
            <br />
            <br />
            <a href="pick-5">
              <h4
                className="museo-sans-300 threeStories"
                style={{ color: "#fff" }}
              >
                {"<< Back"}
              </h4>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
Stories.slideName = "Stories";
Stories.pathname = "/stories";
