import React from "react";

export const People = () => {
  return (
    <div id="people" className="item">
      <div className="fill" style={{ backgroundColor: "white" }}>
        {/* <!-- Container Start --> */}
        <div className="container">
          {/* <!-- Row 1 --> */}
          <div className="row">
            <div
              data-toggle="modal"
              data-target="#myModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage: "url('/images/IMG_4883_CROP.jpg')",
              }}
            ></div>
            <div
              data-toggle="modal"
              data-target="#californiaBurritoModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage: "url('/images/executioners/burrito1.jpg')",
              }}
            ></div>
            <div
              data-toggle="modal"
              data-target="#amyLukkenModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage: "url('/images/executioners/amylukken.jpg')",
              }}
            ></div>
            <div
              data-toggle="modal"
              data-target="#moomjyModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage: "url('/images/executioners/moomjy.jpg')",
              }}
            ></div>
            <div
              data-toggle="modal"
              data-target="#amandaGaddyModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage: "url('/images/executioners/amandagaddy.jpg')",
                backgroundPositionX: "-8px",
              }}
            ></div>
            <div
              data-toggle="modal"
              data-target="#expectMiraclesModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage:
                  "url('/images/executioners/expectmiracles.jpg')",
              }}
            ></div>
            <div
              data-toggle="modal"
              data-target="#shannonDunnModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage:
                  "url('/images/executioners/shannondunn-modal.png')",
              }}
            ></div>
            {/* <!-- End Row 1 --> */}

            {/* <!-- Row 2 --> */}
            <div
              data-toggle="modal"
              data-target="#britishAirwaysModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage:
                  "url('/images/executioners/britishairway.jpg')",
              }}
            ></div>
            <div
              data-toggle="modal"
              data-target="#pmacmurchyModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage: "url('/images/executioners/pmacmurchy.jpg')",
              }}
            ></div>
            <div
              data-toggle="modal"
              data-target="#prolineModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage: "url('/images/executioners/proline.jpg')",
              }}
            ></div>
            <div
              data-toggle="modal"
              data-target="#stephLotzeModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage: "url('/images/executioners/stephlotze.png')",
              }}
            ></div>
            <div
              data-toggle="modal"
              data-target="#4point4Modal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage: "url('/images/executioners/4point4main.png')",
              }}
            ></div>
            <div
              data-toggle="modal"
              data-target="#johnathanBurgessModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage:
                  "url('/images/executioners/jonathanburgess.jpg')",
              }}
            ></div>
            <div
              data-toggle="modal"
              data-target="#nascarModal"
              className="transparent-hoverPointer executionerGrid"
              style={{
                backgroundImage: "url('/images/executioners/nascar.png')",
              }}
            ></div>
          </div>
          {/* <!-- End Row 2 --> */}
        </div>
        {/* <!-- End Container --> */}
        <a href="pick-5">
          <h4
            className="museo-sans-300 threeStories"
            style={{ color: "#333333" }}
          >
            {"<< Back"}
          </h4>
        </a>
      </div>
    </div>
  );
};
People.slideName = "People";
