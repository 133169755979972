import React from "react";

export const ANewBigBlue = () => {
  return (
    <div id="a-new-big-blue" className="item">
      <div
        className="fill"
        style={{ backgroundImage: "url('/images/resizedlanding.jpg')" }}
      >
        <div className="container-fluid">
          <h1 style={{ textAlign: "center" }}>
            <img
              style={{
                width: "65%",
                marginTop: "20px",
                marginBottom: "35px",
              }}
              src="/images/BBF_Logo_edit2.svg"
            />
          </h1>
          <div
            className="col-lg-offset-2 col-md-offset-2 col-lg-3 col-md-3 col-xs-12"
            style={{ color: "white", fontFamily: "helveticaNueueLight" }}
          >
            <p>
              <strong>Infinite Beta Consulting</strong> <br />
              Engaging to improve clients' business/marketing/creative <br />
              strategy by fearlessly asking "What if?" until,{" "}
              <br className="hidden-xs hidden-md hidden-lg" />
              we find "That's IT!"
            </p>
            <p>
              <strong>WhatzBlue Advisors</strong> <br />
              Sorts through clutter, guiding clients through the serious science
              and latest in wellness and human sustainability...{" "}
              <br className="hidden-md hidden-lg" /> work that benefits
              body/mind/spirit and bottom line.
            </p>
          </div>
          <div
            className="col-lg-3 col-md-3 col-xs-12"
            style={{ color: "white", fontFamily: "helveticaNueueLight" }}
          >
            <p>
              <strong>Big Blue Fish 360&#186;</strong> <br />
              Positions, brands, and executes your stories in a media neutral
              environment always asking "How else can we look at this?" Is this
              the very best way to motivate your consumer?
            </p>
            <p>
              <strong>Skajillion: Sustainable Social Media</strong> <br />
              An innovative mash-up connects clients to the conversation
              deploying resources that exist in the company and already
              understand the brand.
            </p>
            <br />
          </div>
          <div
            id="contactSusanModalToggle"
            className="col-lg-2 col-md-2 col-xs-12"
            style={{ color: "white", marginBottom: "20px" }}
          >
            <div style={{ color: "white" }}>
              Connect with{" "}
              <strong className="transparent-hoverPointer">Susan</strong>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
ANewBigBlue.slideName = "ANewBigBlue";
