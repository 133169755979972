//make urls change when clicked
//route to specific url with direct link
// onClick, e.preventDefault() ?
// window.history
//delete divs
//put in correct order
import React, { useState } from "react";
import {
  Pick5,
  Stories,
  ThreeStories,
  InfiniteBeta,
  Delos,
  Executioners,
  Mercedes,
  People,
  Skajillion,
  ANewBigBlue,
  USTA,
  WhatzBlue,
} from "../components/slides";

export const SLIDES_ORDER = [
  Pick5,
  Stories,
  ThreeStories,
  Mercedes,
  Delos,
  USTA,
  InfiniteBeta,
  WhatzBlue,
  Executioners,
  People,
  Skajillion,
  ANewBigBlue,
];

export const getSlideNumber = (slideName) => {
  return SLIDES_ORDER.indexOf(slideName);
};

export const CarouselMobile = () => {
  const [activeIndex, setActiveIndex] = useState(
    //update with setactiveindex
    Math.max(
      SLIDES_ORDER.findIndex(
        (slide) =>
          slide.pathname === window.location.pathname ||
          `/${slide.slideName.toLocaleLowerCase()}` === window.location.pathname
      ),
      0
    )
  );

  const updateActiveSlide = (index) => {
    console.log("make this slide active", index);
    //update the active index state to the index passed in
    //based on active index - find the url or pathname from the component at that index and then update url based on that component
  };
  return (
    <div className="fill">
      <div id="myCarousel" className="carousel slide" data-interval="false">
        <div className="carousel-inner">
          {SLIDES_ORDER.map((Slide, i) => {
            return (
              <Slide
                key={Slide.slideName}
                active={activeIndex === i} //good spot to start for correct slide loading based on URL
                getSlideNumber={getSlideNumber}
                updateActiveSlide={updateActiveSlide}
              />
            );
          })}
          {/* <!-- Controls --> */}
          <div className="controls">
            <a
              className="carousel-control left"
              id="previous"
              href="#myCarousel"
              data-slide="prev"
            >
              <span
                className="glyphicon glyphicon-chevron-left"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Previous</span>
            </a>
            <a
              className="carousel-control right"
              id="next"
              href="#myCarousel"
              data-slide="next"
            >
              <span
                className="glyphicon glyphicon-chevron-right"
                aria-hidden="true"
              ></span>
              <span className="sr-only">Next</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};
