import React from "react";
import { InfiniteBeta } from "./InfiniteBeta";
import { Skajillion } from "./Skajillion";
import { WhatzBlue } from "./WhatzBlue";
import { Stories } from "./Stories";
import { Executioners } from "./Executioners";
/**
 * STRETCH GOAL:
 * Make each if the 5 panel things actually take you to the correct slide
 * clue: you might need to remove the a/anchor tags (e.g. <a href...) to get
 * it to work. Look at the docs for https://bootstrapdocs.com/v3.3.6/docs/javascript/#carousel
 */

export const Pick5 = ({ active = false, getSlideNumber }) => {
  return (
    <div id="pick-5" className={`${active ? "active " : ""}item`}>
      <div className="fill" style={{ backgroundImage: "url('/')" }}>
        {/* <!-- Container Fluid --> */}
        <div
          className="container-fluid white-text text-center"
          style={{
            paddingRight: "0px",
            paddingLeft: "0px",
            height: "100%",
          }}
        >
          {/* <!-- Left Column --> */}
          <div
            className="col-lg-6 col-md-6 col-sm-12"
            style={{
              paddingRight: "0px",
              paddingLeft: "0px",
              height: "100%",
            }}
          >
            <div
              className="col-lg-12"
              style={{
                backgroundImage: "url:('/images/infinite_beta.jpg')",
                height: "20%",
              }}
            >
              <a
                data-target="#myCarousel"
                data-slide-to={getSlideNumber(InfiniteBeta)}
              >
                <h1
                  id="infinite-beta-story"
                  className="slide-link transparent-hoverPointer no-top-bottom-margin"
                  style={{ padding: "5% 0%" }}
                >
                  <img
                    src="/images/infinitebeta_2.svg"
                    height="100%"
                    width="30%"
                    alt="Infinite Beta"
                  />
                </h1>
              </a>
            </div>
            <div
              className="col-lg-12"
              style={{
                backgroundImage: "url('/images/full_page/skajillion.jpg')",
                height: "20%",
              }}
            >
              <a
                data-target="#myCarousel"
                data-slide-to={getSlideNumber(Skajillion)}
              >
                <h1
                  id="skajillion-story"
                  className="slide-link transparent-hoverPointer no-top-bottom-margin"
                  style={{ padding: "5% 0% 0%" }}
                >
                  <img
                    src="/images/skajillion-logo-head_white.png"
                    height="50%"
                    width="50%"
                    alt="Skajillion"
                  />
                </h1>
              </a>
              <p style={{ marginTop: "-15px" }}>Sustainable Social Media</p>
            </div>
            <div
              className="col-lg-12 av-next-condensed-ulight"
              style={{
                backgroundImage: "url(/images/full_page/whatzblue.jpg)",
                height: "20%",
              }}
            >
              <a
                data-target="#myCarousel"
                data-slide-to={getSlideNumber(WhatzBlue)}
              >
                <h1
                  id="whatz-blue-story"
                  className="slide-link transparent-hoverPointer no-top-bottom-margin"
                  style={{ padding: "10% 0%", color: "#fff" }}
                >
                  WHATZBLUE
                </h1>
              </a>
            </div>
            <div
              className="col-lg-12"
              style={{
                backgroundImage: "url('/images/full_page/stories.jpg')",
                height: "20%",
              }}
            >
              <a
                data-target="#myCarousel"
                data-slide-to={getSlideNumber(Stories)}
              >
                <h2
                  id="stories-story"
                  className="slide-link transparent-hoverPointer no-top-bottom-margin"
                  style={{ padding: "10% 0%", color: "#fff" }}
                >
                  stories
                </h2>
              </a>
            </div>
            <div
              className="col-lg-12 av-next-condensed-reg"
              style={{
                backgroundImage: "url('/images/full_page/execution.jpg')",
                height: "20%",
              }}
            >
              <a
                data-target="#myCarousel"
                data-slide-to={getSlideNumber(Executioners)}
              >
                <h3
                  id="executioners-story"
                  className="slide-link transparent-hoverPointer no-top-bottom-margin"
                  style={{ padding: "10% 0%", color: "#fff" }}
                >
                  execution/executioners
                </h3>
              </a>
            </div>
          </div>
          {/* <!-- End Right Column --> */}
        </div>
        {/* <!-- End Container Fluid --> */}
      </div>
    </div>
  );
};
Pick5.slideName = "Pick5";
Pick5.pathname = "/pick-5";
