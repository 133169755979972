import React from "react";
import { People } from "./People";

export const Executioners = ({ active, getSlideNumber }) => {
  //update all of these and classname active
  return (
    <div id="executioners" className={`${active ? "active " : ""}item`}>
      <div
        className="fill"
        style={{
          backgroundImage: "url('/images/full_page/execution.jpg')",
          paddingTop: "15%",
        }}
      >
        <div className="container white-text text-center">
          <h2
            className="av-next-condensed-reg executionersTitle"
            style={{ fontSize: "2.9em" }}
          >
            execution/executioners
          </h2>
          <h4
            className="helvetica-bold"
            style={{
              padding: "10px",
              backgroundColor: "white",
              color: "#807474",
              width: "68%",
              margin: "10px auto",
              fontSize: ".7em",
            }}
          >
            {/* <!-- width:38%; font-size:.7em; --> */}
            HOW A BRILLIANT IDEA GETS FROM YOUR BRAIN
          </h4>
          <h4
            className="helvetica-bold"
            style={{
              padding: "10px",
              backgroundColor: "white",
              color: "#807474",
              width: "56%",
              margin: "10px auto",
              fontSize: ".7em",
            }}
          >
            INTO EVERYBODY ELSE'S
            {/* <!-- font-size:.7em --> */}
          </h4>
          <br />
          <a data-target="#myCarousel" data-slide-to={getSlideNumber(People)}>
            <h4
              className="museo-sans-300 threeStories"
              style={{ color: "#fff" }}
            >
              Meet the Team
            </h4>
          </a>
          <br />
          <br />
          <a href="pick-5">
            <h4
              className="museo-sans-300 threeStories"
              style={{ color: "#fff" }}
            >
              {"<< Back"}
            </h4>
          </a>
        </div>
      </div>
    </div>
  );
};
Executioners.slideName = "Executioners";
Executioners.pathname = "/executioners";
