import React from "react";
import { getSlideNumber } from "../../layouts/CarouselMobile";
import { Mercedes } from "./Mercedes";
import { Delos } from "./Delos";
import { USTA } from "./USTA";

export const ThreeStories = () => {
  return (
    <div id="three-stories" className="item">
      <div className="fill">
        <div
          id="mercedes-story"
          className="slide-link col-xs-12 col-sm-12 col-md-4"
          style={{
            backgroundImage: "url('/images/mercedes-stories2.png')",
          }}
        >
          <a data-target="#myCarousel" data-slide-to={getSlideNumber(Mercedes)}>
            <h2
              className="museo-sans-100 stories-panel-title hoverPointer"
              style={{ color: "#fff" }}
            >
              mercedes
            </h2>
          </a>
        </div>
        <div
          id="delos-story"
          className="slide-link col-xs-12 col-sm-12 col-md-4"
          style={{ backgroundImage: "url('/images/delos-stories2.png')" }}
        >
          <a data-target="#myCarousel" data-slide-to={getSlideNumber(Delos)}>
            <h2
              className="museo-sans-100 stories-panel-title hoverPointer"
              style={{ color: "#fff" }}
            >
              delos
            </h2>
          </a>
        </div>
        <div
          id="usta-story"
          className="slide-link col-xs-12 col-sm-12 col-md-4"
          style={{ backgroundImage: "url('/images/tennis-stories.jpg')" }}
        >
          <a data-target="#myCarousel" data-slide-to={getSlideNumber(USTA)}>
            <h2
              className="museo-sans-100 stories-panel-title hoverPointer"
              style={{ color: "#fff" }}
            >
              usta
            </h2>
          </a>
          <div id="back" className="item"></div>
          <a href="pick-5">
            <h4
              className="museo-sans-300 threeStories"
              style={{ color: "#fff" }}
            >
              {"<< Back"}
            </h4>
          </a>
        </div>
      </div>
    </div>
  );
};
ThreeStories.slideName = "ThreeStories";
