import React from "react";
import { getSlideNumber } from "../../layouts/CarouselMobile";
import { ThreeStories } from "./ThreeStories";

export const USTA = () => {
  return (
    <div id="usta" className="item">
      <div
        className="fill"
        style={{
          backgroundImage: "url('/images/full_page/usta.jpg')",
          backgroundPosition: "initial",
        }}
      >
        <div className="container white-text">
          <h1 className="museo-sans-500" style={{ paddingTop: "5%" }}>
            USTA
          </h1>
          <br />
          <p className="museo-sans-100">
            The opportunity to work with a leading sports development
            organization, making a difference in the global perception of their
            sport, is not one that comes along everyday.
          </p>
          <p className="museo-sans-100">
            At a time when it was most challenging, Karin Buchholz, Director of
            Recreational Tennis, was charged with leading THE USTA in a national
            industry initiative to grow the game of tennis by exposing it to new
            audiences.
          </p>
          <p className="museo-sans-100">
            Thus the Fun-a-facation of tennis was born. PLAY TENNIS AMERICA was
            a grass-roots program, driven by the strategy; Take Tennis from
            Class to Mass, in 8 easy, inexpensive doubles lessons.
          </p>
          <p className="museo-sans-100">
            This strategy debunked all the preconceptions people had about
            tennis (expensive, elitist, overly strenuous) and turned tennis into
            our new tagline: A WHOLE NEW BALLGAME.
          </p>
          <a
            data-target="#myCarousel"
            data-slide-to={getSlideNumber(ThreeStories)}
          >
            <h4
              className="museo-sans-300 threeStories"
              style={{ color: "#fff" }}
            >
              {"<< Back"}
            </h4>
          </a>
        </div>
      </div>
    </div>
  );
};
USTA.slideName = "USTA";
