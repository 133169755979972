import React from "react";
import { getSlideNumber } from "../../layouts/CarouselMobile";
import { ThreeStories } from "./ThreeStories";
export const Delos = ({ active, getSlideNumber }) => {
  return (
    <div id="delos" className={`${active ? "active " : ""}item`}>
      <div
        className="fill"
        style={{ backgroundImage: "url('/images/full_page/delos.jpg')" }}
      >
        <div className="container white-text" style={{ paddingTop: "6%" }}>
          <h1 className="museo-sans-500" style={{ marginTop: "0px" }}>
            delos
          </h1>
          <p className="museo-sans-100">
            If one is very lucky (maybe once in a career) there’s an opportunity
            to be in the room when a game-changing concept is birthed. Such was
            our good fortune when DELOS Founder, Paul Scialla, began to explore
            the possibilities of wellness and the built environment.
          </p>
          <br />
          <p className="museo-sans-100">
            “Humans spend more than 85% of their time indoors,” Paul said and
            then asked, “What would it look like, feel like, be like, if those
            spaces could actually make humans healthier?"
          </p>
          <br />
          <p className="museo-sans-100">
            We found some answers by connecting architects and designers with
            doctors and thought leaders and eventually the story grew to include
            a WELL Building Standard, which changed the way people live, work,
            and play. Thus far, 30 million square feet of space have been
            registered for certification.
          </p>
          <br />
          <p className="museo-sans-100">
            Big Blue Fish is fortunate to continue work with DELOS and others
            who want to put a unique human spin on sustainability and build
            wellness and health in their businesses and their lives.
          </p>
          <div id="delos-quote">
            <h4 className="indexCopy">Susan MacMurchy is brilliant.</h4>
            <p className="indexQuote">
              Paul Scialla, Founder DELOS &amp; IWBI{" "}
            </p>
          </div>
          <br />

          <a
            data-target="#myCarousel"
            data-slide-to={getSlideNumber(ThreeStories)}
          >
            <h4
              className="museo-sans-300 threeStories"
              style={{ color: "#fff" }}
            >
              {"<< Back"}
            </h4>
          </a>
        </div>
      </div>
    </div>
  );
};
Delos.slideName = "Delos";
Delos.pathname = "/delos";
