import React from "react";
import BBFLogo from "./images/BBF-logo.jpeg";
import "./App.css";
import { Wrench } from "./images/wrench";
import styled from "styled-components";
import { CarouselMobile } from "./layouts/CarouselMobile";

const StyledDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 25%;

  svg {
    fill: white;
  }
  @media (max-width: 1200px) {
    width: 80%;
    color: black;
    position: absolute;
    bottom: 20px;
    svg {
      fill: black;
    }
  }
`;

const StyledLogo = styled.img`
  @media (max-width: 1200px) {
    rotate: 90deg;
  }
`;

function App() {
  return (
    <div style={{ height: "100vh" }}>
      <CarouselMobile />;
    </div>
  );
}

export default App;
