import React from "react";

export const InfiniteBeta = () => {
  return (
    <div id="infinite-beta" className="item">
      {/* <!-- remove background:center to make full-screen images fit--> */}
      <div
        className="fill"
        id="infinite-beta-poster-no-video"
        style={{ backgroundImage: "url(/images/infinite_beta.jpg)" }}
      >
        <div
          id="infinite-beta-holder"
          className="container white-text"
          style={{ padding: "8% 8% 2%" }}
        >
          <h2 className="helvetica-nueue">
            {/* <!-- Make it right below the black bar at the top of the video Can disappear and when it does show the line about Elizabeth at the bottom--> */}
            Infinite Beta
          </h2>
          <br />
          <br />
          <p className="helvetica-light">
            Keep doing what you're doing, you'll get what you got.
          </p>
          <br />
          <p>We'll help you slaughter your sacred cows.</p>
          <br />
          <p className="helvetica-light">
            Thanks to “movement’s most brilliant beta” Elizabeth Streb. Her
            willingness to question everything including gravity.
            {/* <!-- Make this small at the bottom like a credit...bang this on all at once--> */}
          </p>
          <a href="pick-5">
            <h4
              className="museo-sans-300 threeStories"
              style={{ color: "#fff" }}
            >
              {"<< Back"}
            </h4>
          </a>
        </div>
      </div>
    </div>
  );
};
InfiniteBeta.slideName = "InfiniteBeta";
