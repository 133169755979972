import React from "react";

export const Skajillion = () => {
  return (
    <div id="skajillion" className="item">
      <div
        className="fill"
        style={{
          backgroundPosition: "inherit",
          backgroundImage: "url('/images/full_page/skajillion.jpg')",
          paddingTop: "14%",
        }}
      >
        <div className="container text-center white-text helvetica-light">
          <h1>
            <img
              src="/images/skajillion-logo-head_white.png"
              height="50%"
              width="50%"
              alt="Skajillion"
            />
            <p>It's not how many you catch it's how many you keep.</p>
            <br />
            <br />
            <a href="pick-5">
              <h4
                className="museo-sans-300 threeStories"
                style={{ color: "#fff" }}
              >
                {"<< Back"}
              </h4>
            </a>
          </h1>
        </div>
      </div>
    </div>
  );
};
Skajillion.slideName = "Skajillion";
